import { toggle } from "@gr-exports/async/toggle";
import type { FunctionImplementation } from "@gr-exports/async/nexus";
import type { OttoNexus } from "../nexus";

const { apm } = otto;

const TOGGLE_NAMESPACE = "rum_custom_error";

export function isSendLogDisabled(): boolean {
  return !toggle.get(TOGGLE_NAMESPACE, true);
}

export function isNamespaceInvalid(namespace: string | undefined): boolean {
  return typeof namespace !== "string" || namespace.length === 0;
}

export function isNamespaceDisabled(namespace: string): boolean {
  return !toggle.get(`${TOGGLE_NAMESPACE}_${namespace.replace(/-/g, "_")}`, true);
}

export function isDataInvalid<Data extends Record<string, unknown>>(data: Data): boolean {
  return !data || typeof data !== "object" || Object.keys(data).length === 0;
}

export function buildMessage(namespace: string, data: Record<string, unknown>): string {
  return namespace.concat(
    ": ",
    Object.entries(data)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", "),
  );
}

/*                                                   */
export type SendLogData = {
  /*                                                                             */
  message?: string;
} & Record<string, LabelValue>;

export const sendCustomError = async function sendCustomError(namespace, data) {
  /*                                                      */
  if (
    isSendLogDisabled() ||
    isNamespaceInvalid(namespace) ||
    isNamespaceDisabled(namespace) ||
    isDataInvalid(data)
  ) {
    return null;
  }

  /*                                                                     */
  const agent = await apm.agent();
  const span = agent?.startSpan("Send Custom Log", "apm.log.custom", { blocking: true });
  /*                                                 */
  const transaction = agent?.getCurrentTransaction();
  const { message, ...rest } = data;
  transaction?.addLabels(rest);

  /*                                                                             */
  /*                                 */
  const container = new Error(message || buildMessage(namespace, rest));
  /*                                                                       */
  container.name = namespace;
  /*                                                           */
  /*                                                   */
  Object.assign(container, rest);

  /*                                                        */
  agent?.captureError(container);
  /*                                                                    */
  span?.end();

  /*                                         */
  return container as never;
} satisfies FunctionImplementation<OttoNexus["apm"]["sendCustomError"]>;
