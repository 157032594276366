import { joinSelectors } from "./utils";

/*                                                                           */
export const PREFIX = "tr-v1";

/*                                            */
export const DATA_BASE_ATTR = `data-${PREFIX}`;

/*                                           */
export const CLASS_SELECTOR = `.${PREFIX}`;

/*                                             */
export const ELEMENT_SELECTOR = joinSelectors([CLASS_SELECTOR, `[${DATA_BASE_ATTR}]`]);

/**
 *
 *
 *
 *
 */
export function getTrackableElements(candidates: NodeList): HTMLElement[] {
  return (
    Array.from(candidates)
      /*                                                       */
      .filter((n): n is HTMLElement => n.nodeType === n.ELEMENT_NODE)
      .flatMap((element) => {
        /*                                                  */
        const elementsToTrack = Array.from(element.querySelectorAll<HTMLElement>(ELEMENT_SELECTOR));

        /*                            */
        if (element.matches(ELEMENT_SELECTOR)) {
          elementsToTrack.push(element);
        }

        return elementsToTrack;
      })
  );
}
