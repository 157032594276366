import { o_global, o_util } from "@gr-common/async/namespaces";

type Join<T extends string[], R extends string = "", D extends string = ", "> = T extends [
  infer F,
  ...infer L,
]
  ? F extends string
    ? L extends [string]
      ? `${F}${R}${D}${Join<L, R, "">}`
      : L extends string[]
        ? `${F}${R}${D}${Join<L, R, D>}`
        : never
    : never
  : "";

/**
 *
 *
 */
export function joinSelectors<const S extends string[]>(selectors: S): Join<S> {
  return selectors.join(", ") as never;
}

/**
 *
 */
export const loggerScope = o_global.debug.scope("tracking.data-tracker");

export const { parseParameters, mergeParameterValues, parseAttributeName } = o_util.attributeParser;
