import type { Tracking } from "../../ottoec/tracking-bct/nexus";
import { ParametersParserOptions } from "../attribute-parser/options";
import { type RecursiveObjectOrValue, replacePlaceholders } from "./object";
import { mergeParameterValues } from "./utils";

/**
 *
 */
export const dataContainerParam = "dataContainer";

/**
 *
 */
export const primitiveParams = ["mergeId", "onLate", "id", "url", "method", "track"] as const;

/**
 *
 */
export const arrayParams = ["features", "updates"] as const;

/**
 *
 */
export const objectParams = [dataContainerParam, "action", "options", "observer"] as const;

export const allParams = [...primitiveParams, ...objectParams, ...arrayParams] as const;

export const parserParametersOptions: ParametersParserOptions<TrackingParametersMap> =
  Object.fromEntries([
    ...arrayParams.map((p) => [p, { array: true }]),
    ...primitiveParams.map((p) => [p, { primitive: true }]),
    ...objectParams.map((p) => [p, { object: true }]),
  ] as const);

/**
 *
 *
 */
export type TrackingParametersMap = {
  [P in (typeof objectParams)[number]]?: Record<string, RecursiveObjectOrValue>;
} & {
  [P in (typeof arrayParams)[number]]?: RecursiveObjectOrValue[];
} & {
  [P in (typeof primitiveParams)[number]]?: string;
};

type AsParamNames<T> = {
  [P in keyof T]: NonNullable<T[P]> extends Array<unknown>
    ? (typeof arrayParams)[number]
    : NonNullable<T[P]> extends object
      ? (typeof objectParams)[number]
      : (typeof primitiveParams)[number];
};
/**
 *
 */
export type ParameterMap = { [P in keyof Tracking]: AsParamNames<Parameters<Tracking[P]>> };

/**
 *
 *
 *
 *
 */
export const parameterMap = {
  createPage: [primitiveParams[3], dataContainerParam],
  submitEvent: [objectParams[0], objectParams[1]],
  submitEventMerge: [primitiveParams[0], dataContainerParam, objectParams[1]],
  submitMerge: [dataContainerParam, arrayParams[0], primitiveParams[1]],
  submitMove: [dataContainerParam, objectParams[1]],
  submitMiniAction: [arrayParams[1]],
  createEventMergeContext: [],
  trackOnNextPageImpression: [dataContainerParam],
  createContext: [primitiveParams[2], primitiveParams[3], dataContainerParam],
  replaceContext: [primitiveParams[2], primitiveParams[3], dataContainerParam],
  closeContext: [],
  getPageMergeId: [],
} as const satisfies ParameterMap;

/**
 *
 *
 *
 *
 *
 *
 */
export function getPropByName(
  obj: Record<string, RecursiveObjectOrValue>,
  name: string,
): RecursiveObjectOrValue {
  return obj[name] ?? obj[name.toLowerCase()];
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function buildParameters<T extends keyof Tracking>(
  method: T,
  eventParams: Record<string, RecursiveObjectOrValue>,
  options: Record<string, RecursiveObjectOrValue>,
  data: Record<string, RecursiveObjectOrValue>,
): Parameters<Tracking[T]> {
  const paramNames = parameterMap[method];
  /*                                                              */
  const parameters = paramNames
    /*                                   */
    .map((key) => {
      const eventValue = getPropByName(eventParams, key);
      const optionValue = getPropByName(options, key);

      if (!eventValue && !optionValue) {
        return undefined;
      }

      return mergeParameterValues(parserParametersOptions[key], eventValue, optionValue);
    })
    /*                                               */
    .map((value) => replacePlaceholders(value, data));

  /*                                                            */
  return parameters;
}
