import { Tracking } from "../../ottoec/tracking-bct/nexus.js";
import { PREFIX } from "./elements.js";
import { DataTrackingEventMap } from "./index.js";
import { ParameterMap, TrackingParametersMap } from "./params.js";

export const dataTrackingEvents = [
  "tr-submit-tracking",
  "tr-track-syntetic",
] as const satisfies (keyof DataTrackingEventMap)[];

export const synteticEvents = [
  "tr-become-visible",
  "tr-page-impression",
] as const satisfies (keyof DataTrackingEventMap)[];

/**
 *
 */
export type TrackingEventDetailData<Method extends keyof ParameterMap> = Partial<{
  [P in ParameterMap[Method][number]]: TrackingParametersMap[P];
}>;

/**
 *
 */
export type TrackingEventDetail<
  M extends keyof ParameterMap,
  T extends TrackingEventDetailData<M>,
  I,
> = I & {
  [PREFIX]: { method?: M } & T;
};

/**
 *
 */
export type EnhanceEvent<
  M extends keyof ParameterMap,
  T extends TrackingEventDetailData<M>,
  I,
> = CustomEvent<TrackingEventDetail<M, T, I>>;

/**
 *
 *
 *
 *
 *
 *
 */
export function createEvent<M extends keyof ParameterMap, T extends TrackingEventDetailData<M>, I>(
  type: string,
  method: M,
  trdata: T,
  init?: CustomEventInit<I>,
): EnhanceEvent<M, T, I> {
  return new CustomEvent<TrackingEventDetail<M, T, I>>(type, {
    ...init,
    detail: { ...(init?.detail as I), [PREFIX]: { method, ...trdata } },
  });
}

export function dispatchTrackingEvent<K extends keyof DataTrackingEventMap>(
  target: EventTarget,
  type: K,
  detail: DataTrackingEventMap[K]["detail"] & {
    [PREFIX]?: { method?: keyof Tracking } & Record<string, unknown>;
  },
): boolean {
  return target.dispatchEvent(
    new CustomEvent(type, { bubbles: true, composed: true, cancelable: true, detail }),
  );
}
