import { o_global, o_util } from "@gr-common/async/namespaces";
import { loggerScope } from "./utils.js";
import { getTrackingEventsFromElement } from "./attributes.js";
import { ELEMENT_SELECTOR, getTrackableElements } from "./elements.js";
import { synteticEvents } from "./event.js";
import { trackingEventListener } from "./listener.js";
import { registerPageImpression } from "./pageImpression.js";
import { registerVisibilityTracking, unregisterVisibilityTracking } from "./visible.js";

const log = loggerScope.scope("init");

export const tracked = new WeakMap<
  HTMLElement,
  [listener: typeof trackingEventListener, string[]]
>();

/**
 *
 *
 *
 *
 */
export function registerTrackingHandler(elementToTrack: HTMLElement): void {
  if (tracked.has(elementToTrack)) {
    return;
  }

  log.trace("Register Data tracker for", elementToTrack);
  const eventsToTrack = getTrackingEventsFromElement(elementToTrack);

  /*                                                                     */
  const listener = trackingEventListener.bind(elementToTrack);

  eventsToTrack.forEach((eventName) => {
    elementToTrack.addEventListener(eventName, listener, { passive: true });
  });

  /*                                                                 */
  tracked.set(elementToTrack, [listener, eventsToTrack]);

  /*                                                     */
  if (eventsToTrack.includes(synteticEvents[0])) {
    registerVisibilityTracking(elementToTrack);
  }

  if (eventsToTrack.includes(synteticEvents[1])) {
    registerPageImpression(elementToTrack);
  }
}

/**
 *
 *
 *
 *
 */
export function unregisterTrackingHandler(elementToTrack: HTMLElement): void {
  const cacheData = tracked.get(elementToTrack);
  if (!cacheData) {
    return;
  }

  /*                                                                          */
  cacheData[1].forEach((eventName) => {
    elementToTrack.removeEventListener(eventName, cacheData[0]);
  });

  /*                                                               */
  unregisterVisibilityTracking(elementToTrack);

  /*                                   */
  tracked.delete(elementToTrack);
}

export const theBody = window.o_util.dom.getBodyElement();

/*                                                                    */
export const observer = new MutationObserver((mutations) => {
  mutations
    .filter((m) => m.type === "childList")
    .forEach((mutation) => {
      const trackableElements = getTrackableElements(mutation.addedNodes);
      trackableElements.forEach(registerTrackingHandler);

      const removedElements = getTrackableElements(mutation.removedNodes);
      removedElements.forEach(unregisterTrackingHandler);
    });
});

export function initializeDataTracker(): void {
  log.info("Initialize Data Tracker");

  /*                                                           */
  /*                                                             */
  theBody.querySelectorAll<HTMLElement>(ELEMENT_SELECTOR).forEach(registerTrackingHandler);

  /*                                     */
  observer.observe(theBody, { childList: true, subtree: true });
}

const pdpPageClusterIds = ["Artikeldetailseite"];
const pdpReloadEventName = "ft5.components.reload";

export function pdpReloadCompleteCallback(): void {
  initializeDataTracker();
  o_global.eventQBus.off(pdpReloadEventName, pdpReloadCompleteCallback);
}

/*                                            */
/*                                */
if (
  o_util.toggle.get("gr_tracking_enable_pdp_workaround", true) &&
  pdpPageClusterIds.includes(o_util.misc.getPagecluster() ?? "")
) {
  log.info("PDP page detected, waiting for reload event to initialize data tracker");
  o_global.eventQBus.on(pdpReloadEventName, pdpReloadCompleteCallback);
} else {
  otto.dom.onLoad.subscribe(initializeDataTracker);
}
