/*                                                 */
import type { Tracking } from "../../ottoec/tracking-bct/nexus";
import { flatObject } from "./object.js";
import {
  buildParameters,
  dataContainerParam,
  parserParametersOptions,
  TrackingParametersMap,
} from "./params.js";
import { dataTrackingEvents, dispatchTrackingEvent } from "./event";
import { loggerScope, parseParameters } from "./utils";
import { DATA_BASE_ATTR } from "./elements";

const log = loggerScope.scope("listener");
const defaultMethod = "submitEvent" as keyof Tracking;

/**
 *
 *
 *
 */
export function isObject(value: unknown): value is object {
  return typeof value === "object" && value !== null;
}

/**
 *
 *
 *
 *
 *
 */
export function getObjectFromProp<T extends object>(obj: object, prop: string): T {
  if (!isObject(obj) || !(prop in obj) || !isObject(obj[prop as never])) {
    return {} as T;
  }

  return obj[prop as never];
}

/**
 *
 *
 *
 *
 */
export function getParametersFromEvent(event: Event): TrackingParametersMap {
  const detail = getObjectFromProp(event, "detail");
  return getObjectFromProp(detail, "tr-v1");
}

/**
 *
 *
 *
 *
 *
 *
 */
export function getTrackingMethod(
  method: string | undefined,
  methodFromEvent: string | undefined,
  options: Record<string, unknown>,
): keyof Tracking {
  /*                                          */
  return method ?? options.method ?? methodFromEvent ?? defaultMethod;
}

/**
 *
 *
 *
 *
 */
export function trackingEventListener(this: HTMLElement, trackedEvent: Event): void {
  /*                       */

  const eventName = trackedEvent.type;
  const elementToTrack = (trackedEvent.currentTarget as HTMLElement) ?? this;
  log.info("Processing incomming tracking event", eventName, elementToTrack);

  /*                                                          */
  const { method: methodFromEvent, ...fromEvent } = getParametersFromEvent(trackedEvent);
  const {
    options = {},
    method,
    ...params
  } = parseParameters(DATA_BASE_ATTR, eventName, elementToTrack, parserParametersOptions, {
    extraParameters: fromEvent,
    expandUnnamedAs: dataContainerParam,
  });

  /*                                                */
  const trackingMethod = getTrackingMethod(method, methodFromEvent, options);

  /*                                                                    */
  const data = flatObject(trackedEvent as never);
  const parameters = buildParameters(trackingMethod, params, options, data);

  log.debug("Trigger pre invocation lifecycle hook");
  if (
    !dispatchTrackingEvent(elementToTrack, dataTrackingEvents[0], {
      method: trackingMethod,
      parameters,
    } as never)
  ) {
    log.debug("Tracking invocation has been terminated by lifecycle hook", trackingMethod);
    return;
  }

  log.info("Invoke tracking via", trackingMethod, parameters);
  /*                                             */
  otto.tracking[trackingMethod](...parameters);
}
