/*                                                  */
import { apm, ApmBase, Transaction, type AgentConfigOptions } from "@elastic/apm-rum";
import { toggle } from "@gr-exports/async/toggle";
import { getPagecluster } from "@gr-exports/async/misc";
import {
  catchFailure,
  fromCondition,
  map,
  mapSpread,
  pipe,
  succeed,
  tap,
  tapSpread,
} from "@otto-ec/assets-core-utils/nano-effect";
import { sendCustomError } from "./sendCustomError";

/**
 *
 */
const sampligRates = { live: 0.02, develop: 1 };

/**
 *
 *
 *
 *
 *
 */
const withTransaction =
  <R, A extends unknown[]>(
    agent: ApmBase,
    withCb: (transaction: Transaction, ...args: NoInfer<A>) => R,
  ): ((...args: A) => R) =>
  (...a) => {
    const transaction = agent.getCurrentTransaction();
    return transaction ? withCb(transaction, ...a) : (undefined as R);
  };

/**
 *
 */
const withApmInitializationConfiguration = fromCondition(
  toggle.get("gr_rum_enable_apm", false),
  () =>
    [
      apm,
      Math.random(),
      sampligRates[import.meta.env.VITE_OTTO_INSTALLATION_ENV],
      /*                                            */
      (window.__APM__ ?? {}) as AgentConfigOptions,
      window.location.hostname,
      getPagecluster() ?? "unknown",
    ] as const,
  () => [() => null, () => undefined] as const,
);

/**
 *
 */
const computeApmActivationRequirements = mapSpread(
  ((agent, rateValue, sampligRate, config, hostname, pagecluster) =>
    [
      agent,
      config,
      pagecluster,
      /*                                                                                     */
      hostname.endsWith("otto.de") && rateValue <= sampligRate,
    ] as const) satisfies (
    ...a: NonNullable<(typeof withApmInitializationConfiguration)[1]>
  ) => unknown,
);

/**
 *
 */
const initializeApmAgent = tapSpread(
  (
    agent: ApmBase,
    config: AgentConfigOptions & { labels?: Labels },
    pagecluster: string,
    isActive: boolean,
  ) => {
    agent.init({
      /*                                                                                    */
      serviceName: import.meta.env.VITE_OTTO_INSTALLATION_NAME,
      serverUrl: import.meta.env.VITE_ELASTIC_APM_SERVER_URL,
      environment: import.meta.env.VITE_OTTO_INSTALLATION_ENV,
      pageLoadTraceId: config.pageLoadTraceId,
      pageLoadSpanId: config.pageLoadSpanId,
      pageLoadSampled: config.pageLoadSampled,
      active: isActive,
    });

    /*                                                    */
    Object.entries({ ...config.labels, pagecluster }).forEach(([key, value]) =>
      agent.addLabels({ [key]: value }),
    );
  },
);

/**
 *
 *
 */
const createNexusNamespaceWithAgentEnabled = map(
  ([agent]: readonly [agent: ApmBase, ...unknown[]]) =>
    ({
      agent: () => agent,
      startSpan: (...a) => agent.startSpan(...a),
      captureError: (...a) => agent.captureError(...a),
      mark: withTransaction(agent, (t, ...a) => t.mark(...a)),
      addLabels: withTransaction(agent, (t, ...a) => t.addLabels(...a)),
      getCurrentTransaction: () => agent.getCurrentTransaction(),
      sendCustomError,
    }) satisfies Parameters<typeof otto.apm.assignNamespace>[0],
);

/**
 *
 *
 */
const createNexusNamespaceWithAgentDisabled = catchFailure(
  ([noopNull, noopUndefined]: NonNullable<(typeof withApmInitializationConfiguration)[0]>) =>
    succeed({
      agent: noopNull,
      startSpan: noopUndefined,
      captureError: noopUndefined,
      mark: noopUndefined,
      addLabels: noopUndefined,
      getCurrentTransaction: noopUndefined,
      sendCustomError: noopNull,
    } satisfies Parameters<typeof otto.apm.assignNamespace>[0]),
);

pipe(
  withApmInitializationConfiguration,
  computeApmActivationRequirements,
  initializeApmAgent,
  createNexusNamespaceWithAgentEnabled,
  createNexusNamespaceWithAgentDisabled,
  tap((nsInput) => otto.apm.assignNamespace(nsInput, { lock: !import.meta.env.TEST })),
);
