import type { Accessibility } from "./index.js";
import type { FunctionImplementation } from "../nexus/function/function.types";

export type SrSpeakOptions = {
  /**
 *
 *
 */
  targetElement?: HTMLElement;
  /**
 *
 *
 */
  type?: "assertive" | "polite";
  /**
 *
 *
 */
  speakerClass?: string;
  /**
 *
 *
 */
  beginTimeout?: number;
  /**
 *
 *
 */
  clearTimeout?: number;
};

export type SrSpeakResult = {
  /**
 *
 */
  speaker: HTMLSpanElement;
  /**
 *
 */
  beginTimeout: number;
  /**
 *
 */
  clearTimeout: number;
};

export const srSpeak = function srSpeak(textToSpeak, options = {}) {
  const {
    type: priority = "polite",
    speakerClass = "oc-visually-hidden",
    beginTimeout = 100,
    clearTimeout: endTimeout = 10000,
    targetElement = document.body,
  } = options;

  /*                     */
  const speaker = document.createElement("span");
  speaker.setAttribute("aria-live", priority);
  speaker.classList.add(speakerClass);

  targetElement.appendChild(speaker);

  return {
    speaker,
    beginTimeout: window.setTimeout(() => {
      speaker.innerHTML = textToSpeak;
    }, beginTimeout),
    clearTimeout: window.setTimeout(() => targetElement.removeChild(speaker), endTimeout),
  };
} satisfies FunctionImplementation<Accessibility["srSpeak"]>;
