import { noop } from "./function";
const win = import.meta.env.SSR ? globalThis : window;
const autoNamespace = /*           */ new Proxy(noop, {
  get: () => autoNamespace
});
if (import.meta.env.SSR) {
  win.AS ||= autoNamespace;
  win.o_global ||= autoNamespace;
  win.o_util ||= autoNamespace;
} else {
  win.AS ||= {};
  win.o_global ||= {};
  win.o_util ||= {};
}
win.__toggles__ ||= {};
const { AS, o_global, o_util, __toggles__ } = win;
export {
  AS,
  __toggles__,
  autoNamespace,
  o_global,
  o_util,
  win as window
};
