import { dispatchTrackingEvent, synteticEvents } from "./event";
import { loggerScope } from "./utils";

const log = loggerScope.scope("page-impression");

/**
 *
 *
 *
 */
export function registerPageImpression(elementToTrack: HTMLElement): void {
  log.info("dispatch page impression", elementToTrack);
  dispatchTrackingEvent(elementToTrack, synteticEvents[1], {
    "tr-v1": { method: "submitMerge" },
  });
}
