import { DATA_BASE_ATTR } from "./elements";
import { dataContainerParam, parserParametersOptions, primitiveParams } from "./params";
import { loggerScope, parseAttributeName } from "./utils";

const log = loggerScope.scope("attributes");

/**
 *
 *
 *
 *
 *
 *
 */
export function getTrackingEventsFromElement(elementToTrack: HTMLElement): string[] {
  const attrs = elementToTrack
    .getAttributeNames()
    .map((a) => parseAttributeName(DATA_BASE_ATTR, a, dataContainerParam, parserParametersOptions))
    .filter(Boolean);

  const track = attrs.find((a) => a[1] === primitiveParams[5]);

  let accumulator: string[];
  if (track) {
    const eventsString = elementToTrack.getAttribute(track[0])!;
    accumulator = eventsString.split(",").map((e) => e.trim());
  } else {
    accumulator = attrs.filter((e) => typeof e[3] === "string").map((e) => e[3] as string);
  }

  log.debug("Extracted events from element", { events: accumulator });
  return Array.from(new Set(accumulator));
}
