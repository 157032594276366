import { DATA_BASE_ATTR, PREFIX } from "./elements.js";
import { dataTrackingEvents, dispatchTrackingEvent, synteticEvents } from "./event.js";
import { dataContainerParam, parserParametersOptions } from "./params.js";
import { loggerScope, parseParameters } from "./utils";

const log = loggerScope.scope("visibility");

export const observed = new WeakMap<
  Element,
  [IntersectionObserver, Required<VisibilityTriggerOptions>]
>();

export const observers = new Map<string, IntersectionObserver>();

export type VisibilityTriggerOptions = {
  /**
 *
 */
  once?: boolean;
};

export type visibilityObserverOptions = IntersectionObserverInit & VisibilityTriggerOptions;

/**
 *
 */
export const VISIBLE_EVENT = synteticEvents[0];

/**
 *
 *
 *
 *
 */
export function unregisterVisibilityTracking(elementToTrack: HTMLElement): void {
  const observerOptions = observed.get(elementToTrack);
  if (!observerOptions) {
    /*                          */
    return;
  }

  /*                                         */
  observerOptions[0].unobserve(elementToTrack);
  observed.delete(elementToTrack);
}

/**
 *
 *
 */
export const visibilityObserverCallback = function visibilityObserverCallback(entries) {
  const elementToTRack = entries[0].target as HTMLElement;

  const observedOptions = observed.get(elementToTRack);
  if (!observedOptions) {
    /*                    */
    return;
  }

  if (observedOptions[1].once) {
    /*                                       */
    unregisterVisibilityTracking(elementToTRack);
  }

  const entry = entries.find((e) => observed.get(e.target));
  if (!entry || !entry.isIntersecting) {
    return;
  }

  dispatchTrackingEvent(entry.target, VISIBLE_EVENT, {
    entry,
    [PREFIX]: { method: "submitMiniAction" },
  });
} satisfies IntersectionObserverCallback;

/**
 *
 *
 *
 */
export function registerVisibilityTracking(elementToTrack: HTMLElement): void {
  if (observed.has(elementToTrack)) {
    /*                */
    return;
  }

  log.info("Initialize visibility tracking", elementToTrack);

  const config = parseParameters(
    DATA_BASE_ATTR,
    VISIBLE_EVENT,
    elementToTrack,
    parserParametersOptions,
    { expandUnnamedAs: dataContainerParam },
  );

  /*                                */
  const { once = false, ...observerInit } =
    (config.observer as Partial<visibilityObserverOptions>) ?? {};

  /*                                           */
  /*                                                     */
  const initID = JSON.stringify(observerInit);

  let observer = observers.get(initID);
  if (!observer) {
    log.info("Instanciating new IntersectionObserver for", observerInit);
    /*                                               */
    if (typeof observerInit.root === "string") {
      observerInit.root = elementToTrack.closest(observerInit.root);
    }

    /*                                                                */
    observer = new IntersectionObserver(visibilityObserverCallback, observerInit);
    observers.set(initID, observer);
  }

  if (
    !dispatchTrackingEvent(elementToTrack, dataTrackingEvents[1], {
      event: synteticEvents[0],
      options: { observer: observerInit },
    })
  ) {
    log.debug("Visibility tracking has been terminated by lifecycle hook", elementToTrack);
    return;
  }

  /*              */
  observed.set(elementToTrack, [observer, { once }]);
  observer.observe(elementToTrack);
}
